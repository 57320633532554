// @flow

import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { FormattedMessageSanitized } from '../FormattedMessageSanitized'
// import { Container } from '../../components/basic/Grid'
import SubpageHeroTeaser from '../../components/SubpageHeroTeaser'
import IntroTeaser from '../IntroTeaser'
// import Services from './Services'
import Expert from './Expert'
import Facts from './Facts'
import Scrollindicator from '../../components/basic/Scrollindicator'
import ScrollAnimation from 'react-animate-on-scroll'
import ClientOverview from '../Clients/ClientOverview'

import messages from './AboutPage.lang'
import baseStyles from './AboutPage.scss'
import { StaticImage } from 'gatsby-plugin-image'

type Props = {
  ...StyleProps
}

const IntroImage = (
  <StaticImage src="../../images/pictures/contact_form_image.jpg" objectFit="cover" alt="" className="image" />
)

const HeroTeaserImage = (
  <StaticImage
    src="../../images/heroteaser/about_heroteaser_bg_desktop.jpg"
    objectFit="cover"
    alt=""
    className="image"
  />
)

const AboutPage = ({ styles }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div className={styles.root}>
      <SubpageHeroTeaser title={formatMessage(messages.pageTitle)} image={HeroTeaserImage} imgAlt="office gallery" />
      <IntroTeaser
        styles={styles.intro}
        title={formatMessage(messages.introTitle)}
        copy={<FormattedMessageSanitized {...messages.introInfo} />}
        image={IntroImage}
        btn={formatMessage(messages.btn)}
        btnLink="/contact/"
        fullWidth
      />
      <ScrollAnimation animateIn="fadeInUp" animatePreScroll={false} animateOnce>
        <Facts />
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInUp" animatePreScroll={false} animateOnce>
        <Expert />
      </ScrollAnimation>
      {/* Removed Servies component */}
      <Scrollindicator />
      <ScrollAnimation animateIn="fadeInUp" animatePreScroll={false} animateOnce>
        <ClientOverview />
      </ScrollAnimation>
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(AboutPage)
