import { defineMessages } from 'react-intl'

export default defineMessages({
  title: 'Facts about freshcells',
  fact1: 'Founded in 2005',
  fact2: 'Holistic software concepts',
  fact3: 'Over 100 satisfied clients',
  fact4: 'Engineering driven',
  fact5: 'Efficient & transparent',
  fact6: 'Strategic partner',
  fact7: 'Interdisciplinary expert teams',
  fact8: 'Realistic & honest'
})
