// @flow

import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import classNames from 'classnames'
import ScrollAnimation from 'react-animate-on-scroll'
import { Container, Row, Col } from '../../../components/basic/Grid'
import messages from './Facts.lang'
import baseStyles from './Facts.scss'

type Props = {
  ...StyleProps,
  hideTitle?: boolean
}

const Facts = ({ styles, hideTitle }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <Container className={styles.root} fluid>
      {!hideTitle && <h2 className={styles.title}>{formatMessage(messages.title)}</h2>}
      <Row>
        <Col xs="6" lg="3" className={styles.col}>
          <ScrollAnimation
            className={styles.animationContainer}
            animateIn="fadeInUp"
            animateOnce
            animatePreScroll={false}
          >
            <span className={classNames(styles.icon, 'icon-flag')} />
            <span className={styles.name}>{formatMessage(messages.fact1)}</span>
          </ScrollAnimation>
        </Col>
        <Col xs="6" lg="3" className={styles.col}>
          <ScrollAnimation
            className={styles.animationContainer}
            animateIn="fadeInUp"
            animateOnce
            delay={100}
            animatePreScroll={false}
          >
            <span className={classNames(styles.icon, styles['is-tertiary'], 'icon-quality')} />
            <span className={styles.name}>{formatMessage(messages.fact2)}</span>
          </ScrollAnimation>
        </Col>
        <Col xs="6" lg="3" className={styles.col}>
          <ScrollAnimation
            className={styles.animationContainer}
            animateIn="fadeInUp"
            animateOnce
            delay={200}
            animatePreScroll={false}
          >
            <span className={classNames(styles.icon, styles['is-accent'], 'icon-heart-filled')} />
            <span className={styles.name}>{formatMessage(messages.fact3)}</span>
          </ScrollAnimation>
        </Col>
        <Col xs="6" lg="3" className={styles.col}>
          <ScrollAnimation
            className={styles.animationContainer}
            animateIn="fadeInUp"
            animateOnce
            delay={300}
            animatePreScroll={false}
          >
            <span className={classNames(styles.icon, styles['is-secondary'], 'icon-memory')} />
            <span className={styles.name}>{formatMessage(messages.fact4)}</span>
          </ScrollAnimation>
        </Col>
        <Col xs="6" lg="3" className={styles.col}>
          <ScrollAnimation
            className={styles.animationContainer}
            animateIn="fadeInUp"
            animateOnce
            animatePreScroll={false}
          >
            <span className={classNames(styles.icon, 'icon-communicate')} />
            <span className={styles.name}>{formatMessage(messages.fact5)}</span>
          </ScrollAnimation>
        </Col>
        <Col xs="6" lg="3" className={styles.col}>
          <ScrollAnimation
            className={styles.animationContainer}
            animateIn="fadeInUp"
            animateOnce
            delay={100}
            animatePreScroll={false}
          >
            <span className={classNames(styles.icon, styles['is-tertiary'], 'icon-help')} />
            <span className={styles.name}>{formatMessage(messages.fact6)}</span>
          </ScrollAnimation>
        </Col>
        <Col xs="6" lg="3" className={styles.col}>
          <ScrollAnimation
            className={styles.animationContainer}
            animateIn="fadeInUp"
            animateOnce
            delay={200}
            animatePreScroll={false}
          >
            <span className={classNames(styles.icon, styles['is-accent'], 'icon-complex')} />
            <span className={styles.name}>{formatMessage(messages.fact7)}</span>
          </ScrollAnimation>
        </Col>
        <Col xs="6" lg="3" className={styles.col}>
          <ScrollAnimation
            className={styles.animationContainer}
            animateIn="fadeInUp"
            animateOnce
            delay={300}
            animatePreScroll={false}
          >
            <span className={classNames(styles.icon, styles['is-secondary'], 'icon-passion')} />
            <span className={styles.name}>{formatMessage(messages.fact8)}</span>
          </ScrollAnimation>
        </Col>
      </Row>
    </Container>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(Facts)
