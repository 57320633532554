// @flow

import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { Container } from '../../../components/basic/Grid'
import messages from './Expert.lang'
import baseStyles from './Expert.scss'
import { StaticImage } from 'gatsby-plugin-image'

const image = <StaticImage src="../../../images/pictures/expert_image.jpg" alt="" objectFit="cover" className="image" />

type Props = {
  ...StyleProps
}

const Expert = ({ styles }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div className={styles.root}>
      <Container className={styles.container} fluid>
        <div className={styles.textWrap}>
          <h2 className={styles.title}>{formatMessage(messages.title)}</h2>
          <p>{formatMessage(messages.info)}</p>
        </div>
        <div className={styles.imgContainer}>{image}</div>
      </Container>
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(Expert)
